import React, {
    createContext,
    useContext,
    useState,
    useEffect
} from 'react';
import moment from "moment";

const UpdateContext = createContext();

export const useUpdateContext = () => {
    return useContext(UpdateContext);
};

export const UpdateProvider = ({
        children
    }) => {

        const [list, setList] = useState([]);
        const [businessclient, setClient] = useState()
        const [supplier, setSupplier] = useState()
        const [courier, setCourier] = useState()
        const [user, setUser] = useState()
        const [country, setCountry] = useState()
        const [dateFrom, setDateFrom] = useState(moment().startOf('month'))
        
        const [dateTo, setDateTo] = useState()
        const [warehouse, setWarehouse] = useState([])
        const [shipTo, setShipTo] = useState([])
        const [product, setProducts] = useState([])
        
        
        const setDropList = ({ name, list }) =>{
                localStorage.setItem(name,(list))
        }

      

        const handleRemoveItem = (i) => {
            const newList = JSON.parse(localStorage.getItem("PO-shopping"));
            newList.splice(i, 1);
            localStorage.setItem("PO-shopping", JSON.stringify(newList));
            setList(newList)
        };

        const handleUpateQuantity = ({
            quantity,
            i
        }) => {
            const newList = JSON.parse(localStorage.getItem("PO-shopping"));
            newList[i].quantity = quantity;

            localStorage.setItem("PO-shopping", JSON.stringify(newList))
            setList(newList)
        };

        const handleAddToPO = (props) => {

            // console.log('adding...',props);
            // console.log(localStorage.getItem("PO-shopping"));

            if (!localStorage.getItem("PO-shopping")) {
                localStorage.setItem("PO-shopping", JSON.stringify([props]))
                setList([props])

            } else {
                let newList = JSON.parse(localStorage.getItem("PO-shopping"));

                newList.push(props);
                localStorage.setItem("PO-shopping", JSON.stringify(newList))
                setList(newList)
            }
        };

        const handleClientChange = (value) => {
            localStorage.removeItem("product")
            localStorage.removeItem("productList")
            setProducts(null)
            
            if(!value||value===''||value===[]) {
                localStorage.removeItem("businessclient")
                setClient(null)
            }
             else {

                localStorage.setItem("businessclient", (value))
                setClient(value)
            }
        }
        const handleSupplierChange = (value) => {
            localStorage.removeItem("product")
            setProducts([])
            if(!value||value===''||value===[]) {
                localStorage.removeItem("supplier")
              
                setSupplier(null)
            } else {

                localStorage.setItem("supplier", (value))
                setSupplier(value)
            }
        }

        const handleProductChange = (value) => {

            
            if(!value||value===''||value===[]) {
                localStorage.removeItem("product")
                setProducts([])
               
            } else {
                // let newList = JSON.parse(localStorage.getItem("product"));
                localStorage.setItem("product", (value))
                setProducts(value)
            }
        }
        const handleCourierChange = (value) => {

             
            if(!value||value===''||value===[]) {
                localStorage.removeItem("courier")
                setCourier(null)
               
            } else {

                localStorage.setItem("courier", (value))
                setCourier(value)
            }
        }
        const handleUserChange = (value) => {
            if(!value||value===''||value===[]) {
                localStorage.removeItem("user")
                setUser(null)
               
            }  else {

                localStorage.setItem("user", (value))
                setUser(value)
            }
        }


        const handleCountryChange = (value) => {
            if(!value||value===''||value===[]) {
                localStorage.removeItem("country")
                setCountry(null)
               
            } else {

                localStorage.setItem("country", (value))
                setCountry(value)
            }
        }
        const handleDateFromChange = (value) => {
            let dateFrom = value
            console.log('dateFrom:', value === null)
            if (value === null) dateFrom = ''

            if (!localStorage.getItem("dateFrom")) {
                localStorage.setItem("dateFrom", (dateFrom))
                setDateFrom(dateFrom)

            } else {

                localStorage.setItem("dateFrom", (dateFrom))
                setDateFrom(dateFrom)
            }
        }
        const handleDateToChange = (value) => {
            let dateTo = value
            if (value === null) dateTo = ''
            if (!localStorage.getItem("dateTo")) {
                localStorage.setItem("dateTo", (dateTo))
                setDateTo(dateTo)

            } else {

                localStorage.setItem("dateTo", (dateTo))
                setDateTo(dateTo)
            }
        }

        const handleWarehouseChange = (value) => {
            if(!value||value===''||value===[]) {
                localStorage.removeItem("warehouse")
                setWarehouse(null)
               
            } else {
                // let newList = JSON.parse(localStorage.getItem("warehouse"));
                localStorage.setItem("warehouse", (value))
                setWarehouse(value)
            }
        }
        const handleShipToChange = (value) => {
            if(!value||value===''||value===[]) {
                localStorage.removeItem("shipTp")
                setShipTo(null)
               
            } else {
                // let newList = JSON.parse(localStorage.getItem("shipTo"));
                localStorage.setItem("shipTo", (value))
                setShipTo(value)
            }
        }

        const resetFilters =()=>{
            
            localStorage.removeItem('businessclient')
            localStorage.removeItem('warehouse')
            localStorage.removeItem('courier')
            localStorage.removeItem('user')
            localStorage.removeItem('product')
            localStorage.removeItem('shipTo')
            localStorage.removeItem('shipFrom')
            localStorage.removeItem('dateTo')
            localStorage.removeItem('dateFrom')
            localStorage.removeItem('courierList')
            localStorage.removeItem('productList')
            localStorage.removeItem('clientsList')
            localStorage.removeItem('usersList')

            
            handleClientChange(null)
            handleCourierChange(null)
            handleUserChange(null)
            handleCountryChange(null)
            handleDateToChange(null)
            handleWarehouseChange([])
            handleProductChange([])
        }

        useEffect(() => {
            resetFilters()

        }, []);

        return ( <UpdateContext.Provider value = {
                {
                    list,
                    setList,
                    handleRemoveItem,
                    handleUpateQuantity,
                    handleAddToPO,

                    resetFilters,
                    
                    handleClientChange,
                    businessclient,

                    handleSupplierChange,
                    supplier,

                    handleProductChange,
                    product,
                    

                    handleCourierChange,
                    courier,

                    handleUserChange,
                    user,

                    handleCountryChange,
                    country,

                    handleWarehouseChange,
                    warehouse,

                    handleShipToChange,
                    shipTo,

                    handleDateFromChange,
                    dateFrom,

                    handleDateToChange,
                    dateTo,

                    setDropList,
                    
                }
            } > {
                children
            } </UpdateContext.Provider>);
        };