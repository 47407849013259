import axios from "axios";
import { store } from "../containers/CRM.jsx";
import { setAuthorizationToken } from "../actions/auth";

const httpClient = axios.create()
export function setTokenHeader(token) {
  if (token) {
    httpClient.defaults.headers.common["Authorization"] = `Barear ${token}`;
    httpClient.defaults.timeout = 1000 *120
    // axios.defaults.headers.common['username'] = username
  } else {
    if(axios.defaults.headers.common["Authorization"]) delete axios.defaults.headers.common["Authorization"];
    // delete axios.defaults.headers.common['username']
  }
}

export function apiCall(method, path, data) {
  return new Promise((resolve, reject) => {
    return httpClient[method](path, data,{
      // onUploadProgress: data => {
      //   console.log('loaded:',data.loaded,data.total)
      //     //Set the progress value to show the progress bar
      //   // setProgress(Math.round((100 * data.loaded) / data.total))
      // }
        
    })
      .then((res) => {

        resolve(res.data)})
      .catch((err) => {
        
        if (err.response === undefined) {
                        // const state = store.getState()
                        // const url =''
                        // const logData = {
                        //         service:'internal.wyzbox.com',
                        //         url:path,
                        //         body:data,
                        //         method,
                        //         code:503,
                        //         user:state.user.currentUser.username,
                        //         serverity:"High"
                        // }
                      
                        // // httpClient.post(url,logData)

          reject({ message: "Reconnecting..." });
        } else {

          if ([401,403,404,406].includes(err.response.status)) {
            localStorage.clear();
            // store.dispatch(removeError())
            setAuthorizationToken(false); //delete from axios default header
            store.dispatch({ type: "USER_LOGOUT" });
            window.location.href = "/signin"; 
          }
          reject(err.response.data.error);
        }
      });
  });
}
