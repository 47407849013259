import React, { useState, useEffect } from "react";
import { Input } from "semantic-ui-react";
import { barcode_decode } from "./../../actions/cashier";
import {Alert} from 'rsuite'
function BarcodeScanner({ inputRef, setScannedProduct, loading,setLoading }) {
  const [barcode, setBarcode] = useState("");

  useEffect(() => {
    if (barcode.length ===13) {
      submitBarcodeToDatabase();
    }
  }, [barcode]);

  useEffect(() => {
    inputRef.current.focus();
  }, [inputRef]);

  const handleBarcodeChange = (e, { value }) => {
    setBarcode(value);
  };
  // Function to submit the barcode to the database

  const submitBarcodeToDatabase = () => {
    setLoading(true)
    // Example: Send barcode to backend API to save in the database
    barcode_decode({ barcode })
      .then((response) => {
        setScannedProduct(response);
        setBarcode("");
        setLoading(false)

      })
      .catch((error) => {
        Alert.error("Barcode not found", 3000);
        setBarcode("");
        setLoading(false)
      });
  };

  return (
    <Input
      loading={loading}
      ref={inputRef}
      name="barcode_scan_input"
      style={{
        marginTop: "30px",
        height: "60px",
        width: "80%",
        alignSelf: "center",
      }}
      type="text"
      value={barcode}
      onChange={handleBarcodeChange}
      placeholder="Scan barcode..."
    />
  );
}

export default BarcodeScanner;
