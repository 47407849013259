import React, { useState, useEffect } from "react";
import { Icon, Segment, Header, Button,Divider} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";

import {
  getShipment,
  updateShipment,
  // getNotinstockbalance
} from "../../../actions/inboundshipment";
import { createCo } from "../../../actions/company";

import Companyform from "../../CompanyForm";
import ShipmentInfo from "./ShipmentInfo";
import Images from "./Images";
import Documents  from "../../../components/Documents";

import GoodsReceived from "../Items/GoodsReceived";
import CanViewPage from "../../../role-based-access/CanViewPage";
import Items from './Items'
import ChangeLogs from '../../ChangeLogs'

import {Alert} from 'rsuite'


function EditViewInboundShipment(props) {
  const {
    createCo,
    match,
    
  } = props;

  const [openShipment,setOpenShipment] = useState({
    warehouse:{_id:null},
    client:{_id:null},
    goodsreceived:[],
    items:[]
  })
  
  const [isLoading,setIsloading] = useState(true)
  const [isCompanyModalOpen, setCompanyModal] = useState(false);
  const [reload,setReload] = useState(false)

  const toggleCompanyModal = () => {
    setCompanyModal(!isCompanyModalOpen);
  };

  const handleUpdateShipment = (query) => {
  setIsloading(true)
    updateShipment({ id: match.params.id, query }).then(resp=>{
      setIsloading(false)
      setOpenShipment(resp)
       if(query.status==='Done')  Alert.success("Email sent.")
    })
  
  };

  
  useEffect(() => {
    setIsloading(true)

          getShipment(match.params.id).then(data=>{
            setOpenShipment(data)
            setIsloading(false)
          })
      }, [match.params.id,reload]);

      

  return (
    <CanViewPage path="/shipments/inbound/:id">
      {/* <Addstockstoragelocation></Addstockstoragelocation> */}
      <main className="page-wrapper a-wrapper">
        <div style={{marginBottom:"20px"}}>
          <Link to="/shipments/inbound/">
            <Icon name="arrow left" /> Received Goods List
          </Link>
         {openShipment.status==='Done'&& <Button
                style={{float:'right'}}
                color='yellow'
                loading={isLoading}
                // fluid
                onClick={() => handleUpdateShipment({ status: "Done" })}
                // disabled={openShipment.status === "Done"}
              >
              Send Email
              </Button>}

        </div>
        
        <Segment padded loading={isLoading}>
          <div className="flex--between">
            <div>
              <Header as="h3" className="edit-inbound-shipment__header">
                Received Shipment - status is {openShipment.status}
              </Header>
              {openShipment._id && (
                <div className="smallinfo">ID: {openShipment._id}</div>
              )}
            </div>
          </div>

          <ShipmentInfo
            openShipment={openShipment}
            handleChange={handleUpdateShipment}
          />

          <Modal
            open={isCompanyModalOpen}
            showCloseIcon={true}
            closeOnEsc={true}
            onClose={toggleCompanyModal}
            center
            classNames={{
              modal: "",
            }}
          >
            <Companyform
              company={openShipment.client} //businessclient
              createCo={createCo}
              closeForm={toggleCompanyModal}
            />
          </Modal>
        </Segment>
        {openShipment.status!=='Done'&&<Button
          loading={isLoading}
          primary={openShipment.status !== "Done"}
          fluid
          onClick={() => handleUpdateShipment({ status: "Done" })}
          // disabled={openShipment.status === "Done"}
        >
          Set Shipment Done (send email)
        </Button>
        }
        <Images
          // setReloadShipment={setReloadShipment}
          openShipment={openShipment}
        />

        <Documents
          businessclient={openShipment.client}
          trigger="Inboundshipment"
          triggerId={props.match.params.id}
        />

        <GoodsReceived
          setReload={setReload}
          reload={reload}
          openShipment={openShipment}
          goodsreceived={openShipment.goodsreceived}
          setShipment={setOpenShipment}
        />

        {/* <Items
          items={openShipment.items}
          businessclient={match.params.id}
          id={openShipment._id}
          setShipment={setOpenShipment}
        ></Items> */}

        {/* <Button onClick={()=>getNotinstockbalance({id:match.params.id})}>Add to stock</Button> */}

        {/* <Stockmovents></Stockmovents>
             <Notinstockitems></Notinstockitems> */}

        <Divider></Divider>
        <ChangeLogs changeLogs={openShipment.changeLogs}></ChangeLogs>
      </main>
    </CanViewPage>
  );
}


export default connect(null, {
  createCo,
})(EditViewInboundShipment);
