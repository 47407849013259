import React, {
  Component
} from "react";
import {
  Menu,
  Sidebar
} from "semantic-ui-react";
import {
  withRouter
} from "react-router-dom";
import {
  connect
} from "react-redux";

import NavbarTop from "../components/Navbar/navbartop";
import Navigation from "../components/Navbar/Navigation";

const breakpoint = 700;

const HOC_Main = (Application) => {
  class SideBar extends Component {
    state = {
      visible: true,
      dimmed: false,
      windowWidth: null
    };

    handleHideClick = () => this.setState({
      visible: false,
      dimmed: false
    });
    handleShowClick = () => this.setState({
      visible: true,
      dimmed: true
    });
    handleSidebarHide = () => this.setState({
      visible: false,
      dimmed: false
    });

    componentDidMount() {
      const width = window.innerWidth;
      this.setState({
        windowWidth: width,
        visible: width > breakpoint
      });
    }

    render() {
      const {
        visible,
        windowWidth
      } = this.state;
      const {
        history
      } = this.props;
      // console.log(history, history.location.path === "/signin");

      return ( <
        div className = "main_app blur" >
        <
        NavbarTop {
          ...this.props
        }
        showLeftMenu = {
          this.handleShowClick.bind(this)
        }
        hideLeftMenu = {
          () => {
            this.setState({
              visible: false,
              dimmed: false
            });
          }
        }
        isSidebarVisible = {
          visible
        }
        />

        <
        div className = "wrapper" >
        <
        Sidebar.Pushable >
        <
        Sidebar as = {
          Menu
        }
        className = "app-sidebar"
        // animation='overlay'
        animation = "slide allong"
        icon = "labeled"
        inverted onHide = {
          windowWidth < breakpoint ? this.handleSidebarHide : null
        }
        vertical visible = {
          history.location.pathname === "/signin" ? false : visible
        }
        width = "thin" >
        <
        div className = "app-sidebar__nav" >
        <
        Navigation handleClick = {
          () => {
            if (windowWidth < breakpoint)
              this.setState({
                visible: false,
                dimmed: false
              });
            return;
          }
        }
        /> <
        /div> <
        /Sidebar>

        <
        Sidebar.Pusher className = {
          visible &&
          windowWidth > breakpoint &&
          history.location.pathname !== "/signin" ?
          "visible-pusher" :
            ""
        } >
        {
          /* HERE IS THE WHOLE APP!!!! */ } <
        Application {
          ...this.props
        }
        showLeftMenu = {
          () =>
          this.setState({
            visible: true,
            dimmed: true
          })
        } >
        < /Application> <
        /Sidebar.Pusher> <
        /Sidebar.Pushable> <
        /div> <
        /div>
      );
    }
  }

  function mapStatetoProps(state) {
    return {
      // componentOpen: state.orderForm.componentOpen
    };
  }
  return withRouter(connect(mapStatetoProps, {})(SideBar));
  // return connect(mapStatetoProps, {})(SideBar)
};
export default HOC_Main;