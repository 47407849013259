import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as Chartjs, defaults } from "chart.js/auto";
import { Segment } from "semantic-ui-react";
import { getPieProductStatuses } from "../../actions/dashboard.js";
import { Alert } from "rsuite";

function Pieproductstatus({ filters, update }) {
  const [data, setData] = useState({ labels: [], datasets: [] });

  const [loading, setLoading] = useState(false);
  const { product } = filters;

  useEffect(() => {
    setLoading(true);
    if (
      (!product || (product && product.length === 0)) &&
      localStorage.getItem("dashProducts")
    )
      getPieProductStatuses({
        ...filters,
        product: localStorage.getItem("dashProducts").split(","),
      })
        .then((resp) => {
          setData(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });

    if (product && product.length > 0)
      getPieProductStatuses(filters)
        .then((resp) => {
          setData(resp);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          Alert.error(err);
        });
  }, [update]);

  return (
    <>
      {" "}
      {/* <h2 style={{ textAlign: "center" }}>Product sales</h2> */}
      <Segment style={{width:"45%",maxWidth:"500px", margin: "20px" }} loading={loading}>
        <Pie
          data={data}
          options={{
            plugins: {
              title: {
                display: true,
                text: `Product Status`,
              },
              legend: {
                display: true,
              },
            },
          }}
        />
      </Segment>
    </>
  );
}
export default Pieproductstatus;
