import React, { useState } from "react";
import {  Tab } from "semantic-ui-react";
import CanViewPage from "../../../role-based-access/CanViewPage";
import Head from "../../Head";
import ListPG from "../Productgroup/ListPG";
import RelatedproductList from "../Relatedproducts/ListPG";
import ProductsList from "./ProductsList";
import Promos from '../Promos'

const linkToTab = {
  product: 0,
  group: 1,
  relatedproduct:2,
  promos:3
};

function ProductsListPage({ match, history }) {

  const initialIndex = linkToTab[match.params.activeTab]; 
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const handleTabChange = (e, { activeIndex }) => {
    history.push(`/products/list/${Object.keys(linkToTab)[activeIndex]}`);
    setActiveIndex(activeIndex);
  };

  const panes = [
    {
      menuItem: "LIST",
      render: () => (
        <Tab.Pane attached={false}>
          <ProductsList />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "GROUPS",
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       <ListPG />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "RELATED PRODUCTS",
      
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       <RelatedproductList />
    //     </Tab.Pane>
    //   ),
    // },
    // {
    //   menuItem: "PROMOS",
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       <Promos />
    //     </Tab.Pane>
    //   ),
    // },
  ];

  return (
    <CanViewPage path="/products/list">
      <>
        <Head headerText={`Product Settings`}>
  
        </Head>
        <main  style={{maxWidth:'1100px',margin:'20px'}}>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={handleTabChange}
          />
        </main>
      </>
    </CanViewPage>
  );
}

export default ProductsListPage;
