import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Segment,
  Header,
  Divider,
  Loader,
  Dimmer,
  Message,
  Button,
} from "semantic-ui-react";
// import { socket } from "../../../containers/Main";
import html2pdf from "html2pdf.js";
import qrCode from "./qr_link.jpeg";

import Barcode from "react-barcode";
// import { apiCall } from "../../../services/api";
// import axios from "axios";
import {
  getNextOrder,
  orderAction,
  getPackerOrder,
  printJob,
  clearState,
  getDocumentsToPrint,
} from "../../../actions/packing";
import { getInvoice, getAwb } from "../../../actions/orders";
import CanViewPage from "../../../role-based-access/CanViewPage";
import ProductsList from "./ProductsList";
import Prints from "./Prints";
import ActionsPrimary from "./ActionsPrimary";
import ActionsSecondary from "./ActionsSecondary";
import TopBar from "./TopBar";
import "./packer.scss";
import { Alert } from "rsuite";

const getTriggerIds = (items) => {
  return items.map((item) => item.product);
};
const initState = {
  docs: [],
  // isBlocking: true,
  // isPacked: false,
  processingInvoice: false,
  barcodePrintedOnload: false,
  printLocal: false,
};
const Packerview = (props) => {
  const [state, setState] = useState(initState);
  const [order, setOrder] = useState({
    clientOrderNr: "",
    payment: { COD: { amount: 0 } },
    processing: {
      instrutions: [],
    },
    errMsgs: [],
    _id: null,
    items: [],
    shipping: {
      shipTo: { client: { firstName: "", lastName: "" } },
      courier: {
        _id: "",
        settings: { sticker_pdf_local: false, printBarcodeOrder: false },
      },
    },
    printInvoice: false,
  });
  const [isLoading, setIsloading] = useState(false);
  const [hitPack, setHitPack] = useState(false);

  const orderRef = useRef(order);

  const setOrderState = (data) => {
    orderRef.current = data.foundOrder;
    setOrder({ ...data.foundOrder, printInvoice: data.printInvoice });
  };

  const hitPackRef = useRef(hitPack);

  const setHitPackRef = (data) => {
    hitPackRef.current = data;
    setHitPack(data);
  };

  const handleDocuemntsToPrint = () => {
    // console.log('printing documents:',orderRef.current)
    const triggerIds = getTriggerIds(orderRef.current.items);

    const id = props.match.params.id;
    const country = orderRef.current.shipping.shipTo.country;

    //Add invoice url if available invoice
    getDocumentsToPrint({ id, triggerIds, country })
      .then((docs) => {
        for (let i = 0; i < docs.length; i++) {
          const file = docs[i];
          // console.log("Printing file:",file)
          for (let j = 0; j < file.urls.length; j++) {
            props.printJob(
              { format: file.format, dataUrl: file.urls[j] },
              "docs"
            );
          }
        }
        setState({ ...state, docs });
      })
      .catch((err) => Alert.error(JSON.stringify(err)));
  };

  const handleBarcodePrintFromBtn = () => {
    setState({ ...state, isBarcodePrinted: true });
    handleBarcodePrint(order._id);
  };

  const handleAWBPrint = (order) => {
    // console.log('prining Local AWB:',order.shipping.courier.settings.sticker_pdf_local)

    if (order.shipping.courier.settings.sticker_pdf_local) {
      setState({ ...state, printLocal: false });
    } else
      props.printJob(
        {
          format: "A6",
          // courier: courier,
          order: order,
        },
        "AWB"
      );
  };

  const handleBarcodeView = (order) => {
    const opt = {
      filename: `barcode-${order.clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: "mm",
        format: "a6",
        // format: [59 ,165],
        // orientation: "l",
        orientation: "p",
      },
    };
    const barcode = document.querySelector(".barcode-container-label");
    html2pdf().set(opt).from(barcode).toPdf().save();
  };

  const handleBarcodePrint = (clientOrderNr) => {
    // alert('barcode')
    const opt = {
      filename: `barcode-${clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        dpi: 192,
        scale: 4,
        letterRendering: true,
        useCORS: true,
      },
      jsPDF: {
        unit: "mm",
        // format: [59 ,165],
        format: "a6",
        orientation: "p",
        // orientation: "l",
      },
    };

    const barcode = document.querySelector(".barcode-container-label");
    html2pdf()
      .set(opt)
      .from(barcode)
      .outputPdf("dataurlstring")
      .then((data) => {
        props.printJob(
          {
            format: "barcode",
            uriData: data,
          },
          "BARCODE"
        );
      });
  };

  const handleInvoiceView = () => {
    setState({ ...state, processingInvoice: true });
    const opt = {
      filename: `invoice-${order.clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1 },
      jsPDF: {
        unit: "mm",
        format: "A4",
        orientation: "p",
      },
    };
    const invoice = document.querySelector("#invoice");
    html2pdf()
      .set(opt)
      .from(invoice)
      .toPdf()
      .save()
      .then(() => setState({ ...state, processingInvoice: false }));
  };

  const handleInvoicePrint = () => {
    // const opt = {
    //   filename: `invoice-${order.clientOrderNr}.pdf`,
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: {
    //     unit: "mm",
    //     format: "A4",
    //     orientation: "p",
    //   },
    // };
    // const invoice = document.querySelector("#invoice");
    // html2pdf()
    //   .set(opt)
    //   .from(invoice)
    //   .outputPdf("dataurlstring")
    //   .then((data) => {
    //     // console.log("printing invoice...", data);
    //     props.printJob(
    //       {
    //         format: "A4",
    //         uriData: data,
    //       },
    //       "INVOICE"
    //     );
    //   });

    if (orderRef.current.invoice) {
      const url = `http://api.internal.wyzbox.com/api/external/invoices/${orderRef.current.invoice._id}/pdf`;
      // setState({...state,docs:[{format:'A4',urls:[url]}]})
      props.printJob({ format: "A4", dataUrl: url }, "invoice");
    }
  };

  const handleSmallInvoicePrint = () => {
    const opt = {
      filename: `invoice-${order.clientOrderNr}.pdf`,
      image: { type: "jpeg", quality: 1 },
      // html2canvas: { scale: 2 },
      jsPDF: {
        unit: "mm",
        format: [90, 200],
        orientation: "p",
      },
    };
    const invoice = document.querySelector("#smallinvoice");
    html2pdf()
      .set(opt)
      .from(invoice)
      .outputPdf("dataurlstring")
      .then((data) => {
        // console.log("printing invoice...", data);
        props.printJob(
          {
            format: "paragon",
            uriData: data,
          },
          "INVOICE"
        );
      });
  };

  const handlePrintUrls = () => {
    props.printJob(
      {
        dataUrl: "test",
        format: "A4",
        order: {},
      },
      "AWB"
    );
    // if(order.print.length>0){
    //   for(let i = 0;i<order.print.length;i++){

    //     const url = order.print[i].url

    //   }
    // }
  };

  const hadndleGetNextOrder = () => {
    setIsloading(true);
    setHitPackRef(false);

    getNextOrder({ orderId: orderRef.current._id })
      .then((resp) => {
        setIsloading(false);
        if (resp.foundOrder._id) {
          props.history.push(`/packer/orders/${resp.foundOrder._id}`);
          // setOrderState(resp)
        } else {
          props.history.push(`/packer/orders`);
          Alert.success("No orders.");
        }
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err.message);
      });
  };

  const handleOrderAction = (action) => {
    // console.log('hitpack is:',hitPackRef.current)
    console.log(action);

    setIsloading(true);
    setHitPackRef(true);

    if (action === "pack") order.status = "Packed";

    orderAction({ action, orderId: orderRef.current._id })
      .then((resp) => {
        setIsloading(false);
        setOrderState(resp);

        // console.log('in order action hitPacked:',hitPackRef.current)
      })
      .catch((err) => {
        setIsloading(false);
        Alert.error(err.message);
      });
  };

  const handleGetAwb = (order) => {
    getAwb(order._id)
      .then((resp) => {
        console.log("RESPONSE:", resp);
        if (resp.status === "success") {
          setOrder({
            ...order,
            shipping: { ...order.shipping, trackingId: resp.trackingId },
          });

          if (resp.dataUrl)
            props.printJob(
              {
                format: resp.format,
                dataUrl: resp.dataUrl,
              },
              "AWB"
            );
        }
        handleDocuemntsToPrint(resp.foundOrder);
        // if(order.shipping.courier.settings.printBarcodeOrder) handleBarcodePrint(resp.foundOrder._id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    setState(initState);
    setIsloading(true);
    getPackerOrder(props.match.params.id)
      .then((resp) => {
        setOrderState(resp);
        setIsloading(false);

        if (
          !resp.foundOrder.shipping.trackingId &&
          !resp.foundOrder.shipping.courier.settings.sticker_pdf_local
        )
          handleGetAwb(resp.foundOrder);
        if (resp.foundOrder.shipping.tracking_awbPdf)
          props.printJob(
            {
              format: resp.foundOrder.shipping.tracking_awbPdf_printFromat,
              dataUrl: resp.foundOrder.shipping.tracking_awbPdf,
            },
            "AWB"
          );
        // handleAWBPrint(resp.foundOrder)

        // handleDocuemntsToPrint(resp.foundOrder)

        if (resp.foundOrder.shipping.courier.settings.printBarcodeOrder)
          handleBarcodePrint(resp.foundOrder._id);
      })
      .catch((error) => {
        console.log(error);
        setIsloading(false);
      });

    // setState({ ...state,isBarcodePrinted: props.isBarcodePrinted });
  }, [props.match.params.id]);

  // const [pdfResponse, setPdfResponse] = useState();

  // const testURL= `http://localhost:8088/getpdf?courier=5ef733212ec6590adfd95286&awb=1ONB24194511291`

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: testURL,
  //     responseType: "arraybuffer",
  //   }).then((response) => console.log(response.data));
  // }, []);

  const docsToPrint = state.docs.map((item) => {
    return (
      <tr>
        <td>{item.name}</td>
        <td>
          <button
            onClinck={() =>
              props.printJob(
                { format: item.format, dataUrl: item.urls[0] },
                "docs"
              )
            }
          >
            print
          </button>
        </td>
      </tr>
    );
  });

  if (isLoading)
    return (
      <Dimmer active inverted>
        <Loader inverted size="medium">
          Loading
        </Loader>
      </Dimmer>
    );

  return (
    <CanViewPage path="/packer/orders/:id">
      {/* {
        Object.keys(order).length ? ( */}
      <main>
        {/* TOP BAR */}
        <section className="top-bar">
          <Segment>
            <TopBar openOrder={order} />
          </Segment>
          {/* <Header as="h3">Packer</Header>
            <Divider /> */}
        </section>
        {order.errMsgs.length > 0 &&
          order.errMsgs.map((err, i) => (
            <div className="alert alert-danger a-wrapper" key={i}>
              {err}
            </div>
          ))}

        {/* INSTRUCTION! */}
        {order.processing.instructions && (
          <div className="a-wrapper">
            <Message error size="huge">
              <Message.Header>Packing Instructions!</Message.Header>
              <p>{order.processing.instructions}</p>
            </Message>
          </div>
        )}
        {/* PACKING INFORMATION */}
        <section className="a-wrapper packing-info flex--between">
          {/* <div className="packing-info__order">
              <Segment>
                <TopBar openOrder={openOrder} openorder._id={openorder._id} />
              </Segment>
            </div> */}
          <div className="packing-info__products">
            <h3>
              Total COD:
              {order.payment.COD.amount}
            </h3>
            <Segment>
              <div className="products-section__wrapper">
                <Header as="h4">Products</Header>
                <Divider />
                <ProductsList items={order.items} />
              </div>
            </Segment>
          </div>

          {/* <span className="vertical-divider" /> */}

          <div className="packing-info__sidebar">
            <Segment>
              <div className="sidebar-container">
                <div className="packing-info__print">
                  <div className="flex--between vertical--center">
                    <Header className="print__header" as="h4">
                      To Print
                    </Header>
                    {/* <Button
                      size="small"
                      color={state.isPrinted ? "" : "grey"}
                      onClick={handlePrint}
                    >
                      <Icon name="print" />
                      {state.isPrinted ? "Printed" : "Print!"}
                    </Button> */}
                  </div>
                  <Divider />
                  <Prints
                    processingInvoice={state.processingInvoice}
                    isInvoicePrinting={props.isInvoicePrinting}
                    isBarcodePrinting={props.isBarcodePrinting}
                    isAWBPrinting={props.isAWBPrinting}
                    isDraft={props.isDraft}
                    handleAWBPrint={() => handleAWBPrint(order)}
                    handleBarcodePrint={handleBarcodePrint}
                    handleBarcodeView={handleBarcodeView}
                    handleInvoiceView={handleInvoiceView}
                    handlePrintUrls={handlePrintUrls}
                    handleInvoicePrint={handleInvoicePrint}
                    handleSmallInvoicePrint={handleSmallInvoicePrint}
                    printInvoice={order.printInvoice}
                    awb={order.shipping.trackingId}
                    awbDatalink={order.shipping.tracking_awbPdf}
                  />
                </div>

                <div className="packing-info__pack-materials">
                  <Header as="h4">Printing files</Header>
                  <Divider />

                  <table>{docsToPrint}</table>

                  <Button onClick={() => handleDocuemntsToPrint()}>
                    Print ALL
                  </Button>
                  {/* <PackingMaterials /> */}
                </div>
              </div>
            </Segment>
          </div>
        </section>

        {/* ACTIONS */}
        <section className="actions  a-wrapper">
          <ActionsPrimary
            handleBarcodePrintFromBtn={handleBarcodePrintFromBtn}
            isBarcodePrinted={props.isBarcodePrinted}
            hitPack={hitPackRef.current}
            hadndleGetNextOrder={hadndleGetNextOrder}
            handlePackedClick={() => {
              handleOrderAction("pack");
            }}
          />

          <ActionsSecondary handleOrderAction={handleOrderAction} />
        </section>

        <div
          style={{
            // display: "none",
            width: "100%",
            // marginLeft: "20px",
          }}
        >
          <div className="barcode-container-label" style={{height:'500px',display:"flex",flexDirection:"column",justifyContent:'space-between'}}>
            <div className="flex--between">
              <Barcode
                value={order.internalNumericId}
                // A4
                // height={340}
                // width={5}
                // fontSize={40}
                // margin={120}

                // A6
                height={80}
                width={1.8}
                fontSize={15}
                margin={20}
                // barcode label size
                // height={40}
                // width={1}
                // fontSize={12}
                // marginTop={3}
                className="barcode"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "between",
                  margin: "20px",
                }}
              >
                <div
                  style={{
                    margin: "10px",
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  WWW.WYZBOX.COM
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  DO NOT COVER!
                </div>
                <div
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  NIE ZAKLEJAĆ KODU!
                </div>
              </div>

            </div>
            <img src={qrCode} alt="QR CODE" width="190" height="190" style={{alignSelf:"center"}}></img>
          </div>
        </div>

        {/* FRONT PDFS TO PRINT
        <div style={{ margin: "10px" }}>
          {!["Packed", "Draft", "PackingError"].includes(order.status) &&
            (order.shipping.courier._id === process.env.REACT_APP_PPL_CZ_ID ||
              order.shipping.courier._id ===
                process.env.REACT_APP_PPL_SK_ID) && (
              <PPLetiket
                // hanldeLocalPrintDone={hanldeLocalPrintDone}
                order={order}
                printJob={props.printJob}
                // trackingId = {order.shipping.trackingId}
                // printLocal={state.printLocal}
              ></PPLetiket>
            )}
        </div> */}
      </main>
    </CanViewPage>
  );
};

function mapStateToProps(reduxState) {
  return {
    isAWBPrinting: reduxState.packing.isAWBPrinting,
    isBarcodePrinted: reduxState.packing.isBarcodePrinted,
    isBarcodePrinting: reduxState.packing.isInvoicePrinting,
    isInvoicePrinted: reduxState.packing.isInvoicePrinted,
    isInvoicePrinting: reduxState.packing.isBarcodePrinting,
    // isPacked: reduxState.packing.isPacked,
    isDraft: reduxState.packing.isDraft,
    // clientSettings: reduxState.businessclients.openClient.settings,
    // invoice: reduxState.orders.invoice,
  };
}

export default connect(mapStateToProps, {
  printJob,
  clearState,
  // getBusinessProfile,
  getInvoice,
})(Packerview);
